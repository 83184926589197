import { graphql, Link } from "gatsby"
import * as React from "react"
import List from "../components/guides/List"
import Layout from "../components/layout/Layout"
import { splitGuides } from "../components/utils/Guide"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faSearch } from "@fortawesome/free-solid-svg-icons"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import Fuse from "fuse.js"
import { HTMLContent } from "../components/common/Content"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        guides: string
        topicalGuides: string
        tutorials: string
      }
    }
    guides: {
      nodes: Array<{
        description: string
        name: string
        path: string
        title: string
        type: string
      }>
    }
  }
}

// markup
const GuidesTemplate = (props: Props) => {
  // const guides = splitGuides(props.data.guides.edges)
  const [search, setSearch] = React.useState<any>()
  const [query, setQuery] = React.useState("")
  const [result, setResult] = React.useState<any>([])

  React.useEffect(() => {
    const options = {
      includeScore: true,
      threshold: 0.3,
      keys: [
        { name: "title", weight: 1 },
        { name: "description", weight: 1 },
      ],
    }
    const fuse = new Fuse(props.data.guides.nodes, options)
    setSearch(fuse)
  }, [props.data.guides.nodes])

  React.useEffect(() => {
    const onSearch = () => {
      if (!search) {
        return
      }
      let vals = props.data.guides.nodes
      if (query.trim()) {
        vals = search.search(query).map((val: any) => val.item)
      }
      setResult(splitGuides(vals))
    }
    onSearch()
  }, [query, search])
  
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="guides"
    >
      <div className="has-background-light">
        <div className="container">
          <div className="hero hero-guides">
            <div className="content">
              <h1 className="is-size-1">Guides</h1>
              <div className="description">
                <HTMLContent content={props.data.page.html} />
              </div>
              <div className="image">
                <img className="img-light" src="/img/guides.svg" alt="" />
                <img className="img-dark" src="/img/guides-dark.svg" alt="" />
              </div>
              <div className="links">
                <div className="columns">
                  <div className="column">
                    <a
                      href="#gettingStarted"
                      className="box is-special has-text-centered border-dark"
                    >
                      <div className="is-size-4 has-text-weight-bold">
                        Getting Started Guides
                      </div>
                      <div className="pb-2">15 - 20 minutes</div>
                      <FontAwesomeIcon className="icon" icon={faAngleDown} />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      href="#topicals"
                      className="box is-special has-text-centered border-dark"
                    >
                      <div className="is-size-4 has-text-weight-bold">
                        Topical Guides
                      </div>
                      <div className="pb-2">60 minutes or less</div>
                      <FontAwesomeIcon className="icon" icon={faAngleDown} />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      href="#tutorials"
                      className="box is-special has-text-centered border-dark"
                    >
                      <div className="is-size-4 has-text-weight-bold">
                        Tutorials
                      </div>
                      <div className="pb-2">2-3 hours</div>
                      <FontAwesomeIcon className="icon" icon={faAngleDown} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <h2 id="gettingStarted" className="is-size-3 has-text-weight-medium">
          Getting Started Guides
        </h2>
        <div className="columns">
          <div className="column is-8">
            <div className="mt-2 mb-6" style={{ maxWidth: 600 }}>
              {props.data.page.frontmatter.guides}
            </div>
          </div>
          <div className="column is-4 pr-6">
            <div className="search">
              <input
                className="input is-special"
                placeholder="Find a guide"
                value={query}
                onChange={event => {
                  setQuery(event.target.value)
                }}
                autoComplete="off"
              />
              <FontAwesomeIcon className="icon" icon={faSearch} />
            </div>
          </div>
        </div>
        {result?.gettingStarted?.length > 0 ? (
          <List icon="guide.svg" items={result.gettingStarted} />
        ) : (
          <div className="pt-6 pb-6 has-text-centered has-text-weight-bold">
            No result found.
          </div>
        )}
      </div>

      {result?.topical?.length > 0 && (
        <div className="has-bigest-border-top mt-6 pt-6">
          <div className="container pb-6">
            <h2 id="topicals" className="is-size-3 has-text-weight-medium">
              Topical Guides
            </h2>
            <div className="mt-2 mb-6" style={{ maxWidth: 600 }}>
              {props.data.page.frontmatter.topicalGuides}
            </div>
            <List icon="topical.svg" items={result.topical} />
          </div>
        </div>
      )}

      {result?.tutorials?.length > 0 && (
        <div className="has-bigest-border-top my-6 pt-6">
          <div className="container pb-6">
            <h2 id="tutorials" className="is-size-3 has-text-weight-medium">
              Tutorials
            </h2>
            <div className="mt-2 mb-6" style={{ maxWidth: 600 }}>
              {props.data.page.frontmatter.tutorials}
            </div>
            <List icon="tutorial.svg" items={result.tutorials} />
          </div>
        </div>
      )}
      <DefaultBottom />
    </Layout>
  )
}

export default GuidesTemplate

// graphql queries
export const pageQuery = graphql`
  query GuidesTemplate($id: String!) {
    guides: allApiGuide {
      nodes {
        description
        name
        title
        type
        path
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        guides
        topicalGuides
        tutorials
      }
    }
  }
`
